<template>
  <BaseTable
    ref="roles-table"
    :resource="$data.$constants.RESOURCES.RESOURCE_ROLES"
    :columns="columns"
    :loading="loading"
    disable-pagination
    hide-totals
    hide-filter-button
    hide-printer-button
    hide-upload-button
    @row-clicked="$router.push({ name: 'viewRole', params: { id: $event.id } })"
  >
    <template #cell(actions)="data">
      <span>
        <b-link
          :to="{ name: 'viewRole', params: { id: data.item.id} }"
          class="d-inline-block text-indigo"
        >
          <feather-icon
            icon="EyeIcon"
            size="18"
          />
        </b-link>
        <b-link
          v-access="{
            resource: $data.$constants.RESOURCES.RESOURCE_CONFIGURATION,
            resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
          }"
          class="d-inline-block text-danger px-1"
          @click="handleDeleteIconClick(data.item)"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Eliminar"
            icon="TrashIcon"
            size="18"
          />
        </b-link>
      </span>
    </template>
  </BaseTable>
</template>

<script>
import ApiRestService from '@/api/base-api'
import BaseTable from '@/components/ui/table/BaseTable.vue'

export default {
  name: 'RolesTable',
  components: { BaseTable },
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'NOMBRE ÚNICO',
          key: 'name',
          sortable: false,
        },
        {
          label: 'DESCRIPCIÓN',
          key: 'display_name',
          sortable: false,
        },
        {
          label: 'Acciones',
          key: 'actions',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    async handleDeleteIconClick(role) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el registro.',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.delete(role.id, this.$data.$constants.RESOURCES.RESOURCE_ROLES)
        await this.$refs['roles-table'].loadData()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
